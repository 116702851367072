import { Guid } from "guid-typescript";
import { IterableListItem } from '@/components/Triggers/types'

/**
 * @deprecated
 */
export class IterableItemFactory {
  create<T>(value: T): IterableListItem<T> {
    return { 'guid': Guid.create().toString(), value }
  }
}
